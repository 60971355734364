<template>
  <div>
    <ScreenSelect :showType="false" @changeTimeRange="selectTime" />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      :scroll="{ x: 1200 }"
      @change="handlePagination"
    >
      <template slot="driverName" slot-scope="text, record">
        <div>
          {{ text }}
          <a-icon v-if="record.faceRecognition === 1" type="user" />
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceTypeEnum } from '@/enum/device';
import { deviceRunningRecord } from '@/services/device-manage/device-detail';
import ScreenSelect from '@/views/safety-device/component/screen-select';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class RunningRecord extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('detail.seTime'),
        width: 240,
        dataIndex: 'startTime',
      },
      {
        align: 'left',
        title: this.$t('detail.operator'),
        dataIndex: 'driverName',
        scopedSlots: { customRender: 'driverName' },
      },
      {
        align: 'left',
        title: this.$t('detail.seFloor'),
        dataIndex: 'startFloor',
      },
      {
        align: 'left',
        title: this.$t('detail.seHeight'),
        width: 200,
        dataIndex: 'startHeight',
      },
      {
        align: 'left',
        title: this.$t('detail.maxSpeed'),
        dataIndex: 'maxSpeed',
      },
      {
        align: 'left',
        title: this.$t('detail.deadWeight'),
        dataIndex: 'weight',
      },
      {
        align: 'left',
        title: this.$t('detail.recognitionNumber'),
        dataIndex: 'population',
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
    }
  }
  mounted() {
    this.getTableList();
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  formateTimeRange(t) {
    let endTime;
    if (t.endTime - t.startTime > 24 * 3600 * 1000) {
      endTime = this.dayjs(t.endTime).format('YYYY/MM/DD HH:mm:ss');
    } else {
      endTime = this.dayjs(t.endTime).format('HH:mm:ss');
    }
    const startTime = this.dayjs(t.startTime).format('YYYY/MM/DD HH:mm:ss');
    return `${startTime}-${endTime}`;
  }
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    try {
      this.loading = true;
      const { records, current, size, total } = await deviceRunningRecord(
        params,
        this.deviceGroup,
      );
      this.tableData = records.map(v => ({
        startTime: this.formateTimeRange(v),
        driverName: v.driverName,
        startHeight: `${v.startHeight}米/${v.endHeight}米`,
        startFloor: `${v.startFloor}层/${v.endFloor}层`,
        maxSpeed: `${v.maxSpeed}米/秒`,
        weight: v.weight + '吨',
        population: v.population,
      }));
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0 20px;
  position: relative;
  overflow: auto;
}
</style>

<template>
  <div :class="$style.detail">
    <DetailHeader :headerData="headerData" />
    <div>
      <a-tabs
        :default-active-key="activeKey"
        :tabBarStyle="{ margin: '10px 20px' }"
        @change="changeTab"
      >
        <a-tab-pane key="device-status" :tab="$t('safety.deviceStatus')">
          <div v-if="activeKey === 'device-status'" :class="$style.listWrap">
            <StatusInfo
              :textObj="baseInfoText"
              :label="$t('detail.baseInfo')"
              :showUnit="true"
              :iconList="baseIcons"
            />
            <StatusInfo
              :textObj="statusText"
              :label="$t('detail.runningStatus')"
              :iconList="statusIcons"
            />
            <StatusInfo
              :textObj="upDeviceText"
              :label="$t('detail.onboardInfo')"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="running-record" :tab="$t('detail.runningRecord')">
          <div v-if="activeKey === 'running-record'" :class="$style.listWrap">
            <RunningRecord :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="up-record" tab="上下机记录">
          <div v-if="activeKey === 'up-record'" :class="$style.listWrap">
            <UpRecord :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="device-warning">
          <span slot="tab">
            <a-badge
              style="width: 40px;"
              :count="alarmCount"
              :number-style="{
                backgroundColor: 'var(--primary)',
                boxShadow: '0 0 0 1px var(--primary) inset',
                top: '-5px',
              }"
            >
              {{ $t('safety.alarm') }}
            </a-badge>
          </span>
          <div v-if="activeKey === 'device-warning'" :class="$style.listWrap">
            <DeviceWarning :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
        <div slot="tabBarExtraContent">
          <div v-if="activeKey === 'device-status'">
            {{ $t('detail.lastUpdateTime') }}：{{ lastUpdateTime }}
          </div>
        </div>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import DetailHeader from '@/views/safety-device/component/detail-header';
import DeviceWarning from '@/views/safety-device/component/device-warning';
import StatusInfo from '@/views/safety-device/component/status-info';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import { deviceSafetyInfo } from '@/services/device-manage/device-detail';
import { deviceTypeEnum } from '@/enum/device';
import RunningRecord from './running-record';
import UpRecord from './up-record';

@Component({
  components: {
    DetailHeader,
    DeviceWarning,
    StatusInfo,
    ScreenSelect,
    RunningRecord,
    UpRecord,
  },
})
export default class LiftDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) detailData;
  @Prop({ type: String, default: '' }) pageType;

  headerData = {};
  mounted() {
    this.initHeadData();
  }
  alarmCount = 0;
  deviceId = '';
  initHeadData() {
    const data = this.detailData;
    this.headerData = {
      deviceName: data.deviceName,
      deviceCode: data.deviceCode,
      bindTime: data.bindTime,
      projectName: data.projectName,
      remark: data.remark,
    };
    this.alarmCount =
      data.alarmCount !== 0 && data.alarmCount !== -1 ? data.alarmCount : 0;
    this.deviceId = data.pkId;
    this.getDeviceInfoList();
  }
  activeKey = 'device-status';
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  changeMinusOne(val) {
    return val !== -1 ? val : '-';
  }
  changeStatus(val) {
    const text = ['正常', '预警', '报警'];
    if (val !== -1) {
      return text[val];
    } else {
      return '-';
    }
  }
  lastUpdateTime = '';
  async getDeviceInfoList() {
    try {
      const res = await deviceSafetyInfo(this.deviceId, this.deviceGroup);
      if (res) {
        for (const key in this.baseInfoText) {
          this.baseInfoText[key].value = this.changeMinusOne(res[key]);
          if (key === 'weight') {
            this.baseInfoText[key].status = this.changeMinusOne(
              res.overWeightStatus,
            );
          } else if (key === 'speed') {
            this.baseInfoText[key].status = this.changeMinusOne(
              res.speedStatus,
            );
          } else if (key === 'windSpeed') {
            this.baseInfoText[key].status = this.changeMinusOne(
              res.windSpeedStatus,
            );
          } else if (key === 'tiltXAngle') {
            this.baseInfoText[key].status = this.changeMinusOne(
              res.obliqueXStatus,
            );
          } else if (key === 'tiltYAngle') {
            this.baseInfoText[key].status = this.changeMinusOne(
              res.obliqueYStatus,
            );
          } else if (key === 'heightFloor') {
            const height = this.changeMinusOne(res.height);
            const floor = this.changeMinusOne(res.floor);
            this.baseInfoText[key].value = `${height}米/${floor}层`;
          }
        }

        for (const key in this.statusText) {
          if (Object.hasOwnProperty.call(this.statusText, key)) {
            this.statusText[key].value = res[key];
          }
        }

        for (const key in this.upDeviceText) {
          if (Object.hasOwnProperty.call(this.upDeviceText, key)) {
            this.upDeviceText[key].value = res[key];
          }
        }

        this.statusText.frontDoorStatus.status = this.changeMinusOne(
          res.frontDoorAlarmStatus,
        );

        this.statusText.numberRecognition.value =
          res.numberRecognition === 1
            ? this.changeMinusOne(res.population)
            : '不支持';

        this.statusText.helmetsIdentification.value =
          res.helmetsIdentification === 1
            ? this.changeMinusOne(res.numberOfHelmets)
            : '不支持';

        this.lastUpdateTime = this.formateTime(res.lastUpdateTime);
      }
    } catch {
      return false;
    }
  }
  baseIcons = ['weight', 'speed', 'windSpeed', 'tiltXAngle', 'tiltYAngle'];
  baseInfoText = {
    weight: {
      text: this.$t('detail.deadWeight'),
      unit: this.$t('unit.ton'),
      value: '-',
      status: '',
    },
    heightFloor: {
      text: '运行高度' + '/' + this.$t('detail.floor'),
      unit: '',
      value: '-',
    },
    speed: {
      text: this.$t('detail.runningSpeed'),
      unit: this.$t('unit.meter') + '/' + this.$t('unit.second'),
      value: '-',
      status: '',
    },
    moveStatus: {
      text: '运行状态',
      value: '-',
      format: val => ['停止', '向上', '向下'][val] ?? '-',
    },
    tiltXAngle: {
      text: this.$t('detail.angleX'),
      unit: this.$t('safety.limit'),
      value: '-',
      status: '',
    },
    tiltYAngle: {
      text: this.$t('detail.angleY'),
      unit: this.$t('safety.limit'),
      value: '-',
      status: '',
    },
    windSpeed: {
      text: this.$t('detail.windSpeed'),
      unit: this.$t('unit.meter') + '/' + this.$t('unit.second'),
      value: '-',
      status: '',
    },
  };
  statusIcons = ['frontDoorStatus'];
  statusText = {
    frontDoorStatus: {
      text: '前门门锁状态',
      value: '-',
      format: val => ['-', '打开', '关闭'][val] ?? '-',
    },
    backDoorStatus: {
      text: this.$t('detail.backDoorStatus'),
      value: '-',
      format: val => ['-', '打开', '关闭'][val] ?? '-',
    },
    populationStatus: {
      text: '人数状态',
      value: '-',
      format: val => ['-', '正常', '报警'][val] ?? '-',
    },
    faceRecognition: {
      text: this.$t('detail.faceRecognition'),
      value: '-',
      format: val => ['不支持', '未识别', '已识别'][val] ?? '-',
    },
    numberRecognition: {
      text: this.$t('detail.numberRecognition'),
      value: '-',
    },
    helmetsIdentification: {
      text: this.$t('detail.helmetsIdentification'),
      value: '-',
    },
    fallStatus: {
      text: this.$t('detail.fallAlarm'),
      value: '-',
      format: val => ['-', '正常', '报警'][val] ?? '-',
    },
    topStatus: {
      text: this.$t('detail.toppling'),
      value: '-',
      format: val => ['-', '正常', '报警'][val] ?? '-',
    },
  };
  upDeviceText = {
    driverName: {
      text: this.$t('detail.driverName'),
      value: '-',
    },
    driverIdentityNumber: {
      text: this.$t('detail.driverIdentityNumber'),
      value: '-',
    },
    faceTime: {
      text: this.$t('detail.onBoardTime'),
      value: '-',
      format: val => this.formateTime(val),
    },
  };
}
</script>

<style lang="less" module>
@import '../component/safety.less';
</style>

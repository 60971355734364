<template>
  <div :class="$style.table">
    <ScreenSelect :showType="false" @changeTimeRange="selectTime" />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    >
      <template slot="capture" slot-scope="text">
        <oss-image v-if="text" basePath="/oss/iot/oss" :ossPath="text" />
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceUpRecord } from '@/services/device-manage/device-detail';
import { deviceTypeEnum } from '@/enum/device';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import OssImage from '@/views/screen/components/oss-image.vue';

@Component({
  components: {
    ScreenSelect,
    OssImage,
  },
})
export default class UpRecord extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '类型',
        dataIndex: 'type',
        width: 80,
        customRender: text => ['上机', '下机'][text] ?? '-',
      },
      {
        align: 'left',
        title: this.$t('safety.time'),
        dataIndex: 'faceTime',
        customRender: txt => this.formateTime(txt),
      },
      {
        align: 'left',
        title: this.$t('detail.name'),
        dataIndex: 'driverName',
      },
      {
        align: 'left',
        title: this.$t('detail.identityCode'),
        dataIndex: 'driverIdentityNumber',
      },
      {
        align: 'left',
        title: this.$t('detail.capture'),
        dataIndex: 'capture',
        scopedSlots: { customRender: 'capture' },
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
    }
  }
  mounted() {
    this.getTableList();
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    try {
      this.loading = true;
      const { records, current, size, total } = await deviceUpRecord(
        params,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>
<style lang="less" module>
.table {
  .img {
    height: 38px;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
